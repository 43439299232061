const ROOT_ROUTE = "/" as const

const COMMUNITY_ROUTE_PREFIX = ROOT_ROUTE

const ONBOARDING_ROUTE_PREFIX = `${ROOT_ROUTE}onboarding` as const

const ADMIN_ROUTE_PREFIX = `${ROOT_ROUTE}admin` as const
const ADMIN_SETTINGS_PREFIX = `${ADMIN_ROUTE_PREFIX}/settings` as const
const BRAIN_ROUTE_PREFIX = `${ROOT_ROUTE}brain` as const

const PRODUCT_ROUTE_PREFIX = `${ROOT_ROUTE}p` as const
const DM_DETAIL_ROUTE_PREFIX = `${ROOT_ROUTE}dm` as const
const THREAD_DETAIL_ROUTE_PREFIX = `${ROOT_ROUTE}thread` as const
const PRODUCT_DETAIL_ROOT = `/p/:productSlug` as const

const CHANNEL_ROUTE_PREFIX = `${ROOT_ROUTE}channel` as const
const CHAT_ROUTE_PREFIX = `${ROOT_ROUTE}chat` as const

const COMMUNITY_EVENTS_PREFIX = `${ROOT_ROUTE}events` as const

const CURRICULUM_ROUTE_PREFIX = `${ROOT_ROUTE}curriculum` as const
const GROUP_DIRECTORY_ROUTE_PREFIX = `${ROOT_ROUTE}group-directory` as const

const ROUTE_NAMES = {
  ROOT: ROOT_ROUTE,

  CHECKOUT: {
    PLAYGROUND: `${ROOT_ROUTE}checkout-playground`,
    SUMMARY: `${ROOT_ROUTE}checkout/:cart/summary`,
    PAYMENT: `${ROOT_ROUTE}checkout/:validCheckoutId/payment`,
    PROCESS: `${ROOT_ROUTE}checkout/:validCheckoutId/process`,
    COMPLETE: `${ROOT_ROUTE}checkout/:validCheckoutId/complete`,
  },

  ONBOARDING: {
    // Sign-Up Flow
    SIGN_UP: `${ROOT_ROUTE}sign-up`,
    V2: {
      GETTING_STARTED: `${ONBOARDING_ROUTE_PREFIX}/getting-started`,
      COMMUNITY_WEBSITE: `${ONBOARDING_ROUTE_PREFIX}/community-website`,
      COMMUNITY_SIZE: `${ONBOARDING_ROUTE_PREFIX}/community-size`,
      REGISTRATION_CREATE_PROFILE: `${ONBOARDING_ROUTE_PREFIX}/create-profile`,
      NEW_COMMUNITY: `${ONBOARDING_ROUTE_PREFIX}/new-community`,
      USE_CASE_GROUP: `${ONBOARDING_ROUTE_PREFIX}/use-case`,
      COMMUNITY_INTERNAL_TEMPLATE: `${ONBOARDING_ROUTE_PREFIX}/internal-template`,
      COMMUNITY_EXTERNAL_TEMPLATE: `${ONBOARDING_ROUTE_PREFIX}/external-template`,
    },
  },

  AUTHENTICATION: {
    NEW_EMAIL_CONFIRMATION: `${ROOT_ROUTE}new-email-confirmation`,
    SET_EMAIL: `${ROOT_ROUTE}set-email`,
    LOGIN: `${ROOT_ROUTE}login`,
    LOGIN_WITH_LINK: `${ROOT_ROUTE}login-with-link`,
    LOGIN_WITH_TOKEN: `${ROOT_ROUTE}login-with-token`,
    LOGOUT: `${ROOT_ROUTE}logout`,
    FORGOT_PASSWORD: `${ROOT_ROUTE}forgot-password`,
    CHANGE_PASSWORD: `${ROOT_ROUTE}change-password`,
    RESET_PASSWORD: `${ROOT_ROUTE}reset-password`,
    VERIFY_EMAIL_SENT: `${ROOT_ROUTE}verify-email-sent`,
    SOCIAL_AUTH_REDIRECT: `${ROOT_ROUTE}auth/redirect`,
    TOKEN_EXCHANGE: `${ROOT_ROUTE}auth/exchange`,
    VERIFY_EMAIL: `${ROOT_ROUTE}verify-email`,
    VERIFY_ADMIN_INVITATION: `${ROOT_ROUTE}verify-admin-invitation`,
    LINK_SLACK: `${ROOT_ROUTE}link-slack`,
    SSO_AUTH: `${ROOT_ROUTE}auth/sso`,
  },

  COMMUNITY: {
    HOME: {
      ROOT: `${COMMUNITY_ROUTE_PREFIX}home`,
      FOR_YOU: `${COMMUNITY_ROUTE_PREFIX}for-you`,
    },
    LANDING_PAGE: {
      SITE: COMMUNITY_ROUTE_PREFIX,
      ADMIN_ROOT: `${COMMUNITY_ROUTE_PREFIX}landing-page`,
      PREVIEW: `${COMMUNITY_ROUTE_PREFIX}landing-page/:landingPageId/preview`,
      EDIT: `${COMMUNITY_ROUTE_PREFIX}landing-page/:landingPageId/edit`,
      EDIT_MARKETING: `${COMMUNITY_ROUTE_PREFIX}landing-page/edit/marketing`,
      LEGACY_PREVIEW: `${COMMUNITY_ROUTE_PREFIX}landing-page/preview`,
      LEGACY_EDIT: `${COMMUNITY_ROUTE_PREFIX}landing-page/edit`,
    },
    MEMBERSHIPS: {
      ROOT: `${COMMUNITY_ROUTE_PREFIX}memberships`,
    },
    EXPERIENCES: {
      ROOT: `${COMMUNITY_ROUTE_PREFIX}experiences`,
      COURSES: {
        ROOT: `${COMMUNITY_ROUTE_PREFIX}experiences/courses`,
        UPCOMING: `${COMMUNITY_ROUTE_PREFIX}experiences/courses/upcoming`,
        PAST: `${COMMUNITY_ROUTE_PREFIX}experiences/courses/past`,
        ALL: `${COMMUNITY_ROUTE_PREFIX}experiences/courses/all`,
      },
      PATHWAYS: { ROOT: `${COMMUNITY_ROUTE_PREFIX}experiences/pathways` },
    },
    LIBRARY: {
      ROOT: `${COMMUNITY_ROUTE_PREFIX}library`,
    },
    COLLECTION: {
      ROOT: `${COMMUNITY_ROUTE_PREFIX}collection`,
      DETAIL: `${COMMUNITY_ROUTE_PREFIX}collection/:collectionId`,
    },
    MEMBERS: {
      LIST: `${COMMUNITY_ROUTE_PREFIX}members`,
    },
    MEMBER_DIRECTORY: `${COMMUNITY_ROUTE_PREFIX}member-directory/:appId`,
    GROUP_DIRECTORY: {
      ROOT: `${GROUP_DIRECTORY_ROUTE_PREFIX}`,
      DETAIL: `${GROUP_DIRECTORY_ROUTE_PREFIX}/:groupDirectoryId`,
    },
    JOIN: {
      ROOT: `${ROOT_ROUTE}join-community`,
      SUCCESS: `${ROOT_ROUTE}join-community/thankyou`,
      INVITE_LINK: `${ROOT_ROUTE}join-community?inviteKey=:inviteKey`,
    },
    // Community events page
    EVENTS: {
      ROOT: `${ROOT_ROUTE}events`,
      APP: `${ROOT_ROUTE}events/:appId`,
    },
    // Global events page, including both community and product events
    EVENTS_CALENDAR: {
      ROOT: `${ROOT_ROUTE}events-calendar`,
    },
    SHARE: {
      OCCURRENCE: {
        ROOT: `${ROOT_ROUTE}share/o/:occurrenceId`,
      },
    },
    CHAT: {
      CHANNEL: `${CHANNEL_ROUTE_PREFIX}/:channelId`,
    },
    DIRECT_MESSAGES: {
      ROOT: `${COMMUNITY_ROUTE_PREFIX}direct-messages`,
      DETAIL: `${DM_DETAIL_ROUTE_PREFIX}/:channelId`,
      DETAIL_ROOT: DM_DETAIL_ROUTE_PREFIX,
      DETAIL_EMPTY: `${DM_DETAIL_ROUTE_PREFIX}/empty`,
    },
    THREADS: {
      ROOT: `${COMMUNITY_ROUTE_PREFIX}threads`,
      DETAIL: `${THREAD_DETAIL_ROUTE_PREFIX}/:threadId`,
      DETAIL_ROOT: THREAD_DETAIL_ROUTE_PREFIX,
      DETAIL_EMPTY: `${THREAD_DETAIL_ROUTE_PREFIX}/empty`,
    },
    WEBVIEW: {
      ROOT: `${COMMUNITY_ROUTE_PREFIX}webview`,
      BOOKMARKS: `${COMMUNITY_ROUTE_PREFIX}webview/bookmarks`,
      PROFILE: `${COMMUNITY_ROUTE_PREFIX}webview/profileSettings`,
      QUIZ: `${COMMUNITY_ROUTE_PREFIX}webview/quiz`,
      ASSIGNMENT: {
        SUBMISSION: `${COMMUNITY_ROUTE_PREFIX}webview/assignment/submission/:assignmentSubmissionId`,
      },
      EDITOR: `${COMMUNITY_ROUTE_PREFIX}webview/editor/:contentUsageId`,
    },
    FEED: {
      ROOT: `${COMMUNITY_ROUTE_PREFIX}feed`,
      POSTS: {
        LIST: `${COMMUNITY_ROUTE_PREFIX}feed/:feedId/posts`,
      },
    },
    DASHBOARD_APP: {
      ROOT: `${COMMUNITY_ROUTE_PREFIX}page-app`,
      DETAIL: `${COMMUNITY_ROUTE_PREFIX}page-app/:appId`,
    },
    GUEST: {
      DETAIL: `${COMMUNITY_ROUTE_PREFIX}g/:entityId`,
    },
  },

  ADMIN: {
    DASHBOARD: `${ADMIN_ROUTE_PREFIX}/dashboard`,
    MEMBERS: {
      ROOT: `${ADMIN_ROUTE_PREFIX}/members`,
      INVITES: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/members/invites`,
      },
      APPLICATIONS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/members/applications`,
      },
      GROUPS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/members/groups`,
      },
      PROFILE_FIELDS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/members/profile-fields`,
      },
      ONBOARDING: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/members/onboarding`,
      },
      GUESTS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/members/guests`,
      },
      IMPORTED_MEMBERS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/members/imported-members`,
      },
      ROLES: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/members/roles`,
      },
    },
    EVENTS: {
      ROOT: `${ADMIN_ROUTE_PREFIX}/events`,
    },
    CONTENT: {
      ROOT: `${ADMIN_ROUTE_PREFIX}/content`,
      MEDIA_ASSETS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/content/assets`,
      },
      SURVEYS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/content/surveys`,
      },
    },
    PRODUCTS: {
      ROOT: `${ADMIN_ROUTE_PREFIX}/products`,
      TRANSACTIONS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/products/transactions`,
      },
      PATHWAYS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/products/pathways`,
      },
      MEMBERSHIPS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/products/memberships`,
      },
      DISCOUNTS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/products/discounts`,
        CREATE: `${ADMIN_ROUTE_PREFIX}/products/discounts/new`,
        EDIT: `${ADMIN_ROUTE_PREFIX}/products/discounts/:discountId/edit`,
        DETAIL: `${ADMIN_ROUTE_PREFIX}/products/discounts/:discountId`,
      },
      PUBLIC_PAGES: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/products/public-pages`,
      },
      MEDIA_LIBRARY: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/products/media-library`,
      },
    },
    AUTOMATIONS: `${ADMIN_ROUTE_PREFIX}/automations`,
    INSIGHTS: {
      ROOT: `${ADMIN_ROUTE_PREFIX}/insights`,
      ENGAGEMENT: `${ADMIN_ROUTE_PREFIX}/insights/engagement`,
      MESSAGES: `${ADMIN_ROUTE_PREFIX}/insights/messages`,
      PRODUCTS: `${ADMIN_ROUTE_PREFIX}/insights/p`,
      PRODUCT: `${ADMIN_ROUTE_PREFIX}/insights${PRODUCT_DETAIL_ROOT}`,
      MEMBERS: `${ADMIN_ROUTE_PREFIX}/insights/members`,
      GROUPS: `${ADMIN_ROUTE_PREFIX}/insights/groups`,
      PATHWAYS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/insights/pathways`,
        PATHWAY: `${ADMIN_ROUTE_PREFIX}/insights/pathways/:productSlug`,
      },
    },
    INTEGRATIONS: {
      ROOT: `${ADMIN_ROUTE_PREFIX}/integrations`,
      LIST: `${ADMIN_ROUTE_PREFIX}/integrations`,
      ZAPIER: `${ADMIN_ROUTE_PREFIX}/integrations/zapier`,
      SETUP_COMMUNITY_SLACK: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/integrations/setup-community-slack`,
        SUCCESS: `${ADMIN_ROUTE_PREFIX}/integrations/setup-community-slack/success`,
      },
      SETUP_ADMIN_SLACK: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/integrations/setup-admin-slack`,
        SUCCESS: `${ADMIN_ROUTE_PREFIX}/integrations/setup-admin-slack/success`,
      },
      SLACK_FINALIZE_CONNECTION: `${ADMIN_ROUTE_PREFIX}/integrations/slack-finalize-connection`,
    },
    APPEARANCE: {
      ROOT: `${ADMIN_ROUTE_PREFIX}/appearance`,
      DOMAIN: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/appearance/domain`,
      },
      LABELS: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/appearance/labels`,
      },
      SIDEBAR: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/appearance/sidebar`,
      },
    },
    SETTINGS: {
      ROOT: `${ADMIN_SETTINGS_PREFIX}`,
      REGISTRATION: {
        ROOT: `${ADMIN_SETTINGS_PREFIX}/registration`,
      },
      LABS: {
        ROOT: `${ADMIN_SETTINGS_PREFIX}/labs`,
      },
      ADVANCED: {
        ROOT: `${ADMIN_SETTINGS_PREFIX}/advanced`,
      },
      BILLING: {
        ROOT: `${ADMIN_SETTINGS_PREFIX}/billing`,
      },
      WEBHOOKS: {
        ROOT: `${ADMIN_SETTINGS_PREFIX}/webhooks`,
      },
    },
    AI: {
      ROOT: `${ADMIN_ROUTE_PREFIX}/ai`,
      TRAINING_SOURCES: {
        ROOT: `${ADMIN_ROUTE_PREFIX}/ai/training-sources`,
      },
    },
    ONBOARDING_CHECKLIST: `${ADMIN_ROUTE_PREFIX}/onboarding-checklist`,
  },
  BRAIN: {
    ROOT: BRAIN_ROUTE_PREFIX,
    SEARCH: `${BRAIN_ROUTE_PREFIX}/search/:searchId`,
  },
  PUBLIC_BRAIN: {
    ROOT: `${ROOT_ROUTE}public-brain`,
    SHARE: `${ROOT_ROUTE}public-brain/share/:shareId`,
  },
  PRODUCT: {
    ROOT: PRODUCT_DETAIL_ROOT,
    REGISTRATION: {
      ROOT: `${PRODUCT_DETAIL_ROOT}/register`,
    },
    COMPLETE_APPLICATION: `${PRODUCT_DETAIL_ROOT}/complete-application`,
    CHAT: {
      LIST: `${PRODUCT_DETAIL_ROOT}${CHAT_ROUTE_PREFIX}`,
      CHANNEL: `${PRODUCT_DETAIL_ROOT}${CHAT_ROUTE_PREFIX}/:channelId`,
      DIRECT_MESSAGE: `${PRODUCT_DETAIL_ROOT}/dm/:channelId`,
    },
    COLLECTION: {
      ROOT: `${PRODUCT_DETAIL_ROOT}/collection`,
      DETAIL: `${PRODUCT_DETAIL_ROOT}/collection/:collectionId`,
    },
    DASHBOARD: `${PRODUCT_DETAIL_ROOT}/dashboard`,
    MEMBERS: {
      ROOT: `${PRODUCT_DETAIL_ROOT}/members`,
      LIST: `${PRODUCT_DETAIL_ROOT}/members`,
      INVITES: `${PRODUCT_DETAIL_ROOT}/members/invites`,
      APPLICATIONS: `${PRODUCT_DETAIL_ROOT}/members/applications`,
    },
    MEMBER_DIRECTORY: `${PRODUCT_DETAIL_ROOT}/member-directory/:appId`,
    FEED: {
      ROOT: `${PRODUCT_DETAIL_ROOT}/feed`,
      POSTS: {
        LIST: `${PRODUCT_DETAIL_ROOT}/feed/:feedId/posts`,
        DETAIL: `${PRODUCT_DETAIL_ROOT}/feed/:feedId/posts/:postId`,
      },
    },
    CURRICULUM: {
      ROOT: `${PRODUCT_DETAIL_ROOT}${CURRICULUM_ROUTE_PREFIX}`,
      OVERVIEW: `${PRODUCT_DETAIL_ROOT}${CURRICULUM_ROUTE_PREFIX}/overview`,
      MODULE: `${PRODUCT_DETAIL_ROOT}${CURRICULUM_ROUTE_PREFIX}/overview#:contentModuleUsageId`,
    },
    RESOURCES: {
      LIST: `${PRODUCT_DETAIL_ROOT}/resources`,
      DETAIL: `${PRODUCT_DETAIL_ROOT}/resources/:resourceId`,
      ADD: `${PRODUCT_DETAIL_ROOT}/resources/new`,
      EDIT: `${PRODUCT_DETAIL_ROOT}/resources/:resourceId/edit`,
    },
    GROUP_DIRECTORY: {
      ROOT: `${PRODUCT_DETAIL_ROOT}${GROUP_DIRECTORY_ROUTE_PREFIX}`,
      DETAIL: `${PRODUCT_DETAIL_ROOT}${GROUP_DIRECTORY_ROUTE_PREFIX}/:groupDirectoryId`,
    },
    LANDING_PAGE: {
      SITE: PRODUCT_DETAIL_ROOT,
      PREVIEW: `${PRODUCT_DETAIL_ROOT}/landing-page/preview`,
      EDIT: `${PRODUCT_DETAIL_ROOT}/landing-page/edit`,
    },
    EVENTS: {
      LIST: {
        ROOT: `${PRODUCT_DETAIL_ROOT}/events`,
        UPCOMING: `${PRODUCT_DETAIL_ROOT}/events/upcoming`,
        PAST: `${PRODUCT_DETAIL_ROOT}/events/past`,
      },
      ADD: `${PRODUCT_DETAIL_ROOT}/events/new`,
      REGISTRATION: {
        ROOT: `${PRODUCT_DETAIL_ROOT}/events/:occurrenceId/register`,
      },
    },
    SHARE: {
      OCCURRENCE: {
        ROOT: `${PRODUCT_DETAIL_ROOT}/share/o/:occurrenceId`,
      },
    },
    REPORTS: {
      ROOT: `${PRODUCT_DETAIL_ROOT}/reports`,
      PROGRESS: {
        ROOT: `${PRODUCT_DETAIL_ROOT}/reports/progress`,
        DRAWER_PATH: "/drawer/:productMembershipId",
        SUMMARY: {
          ROOT: `${PRODUCT_DETAIL_ROOT}/reports/progress/summary`,
          DRAWER_PATH: "/drawer/:productMembershipId/summary",
        },
        CURRICULUM: {
          ROOT: `${PRODUCT_DETAIL_ROOT}/reports/progress/curriculum`,
          DRAWER_PATH: "/drawer/:productMembershipId/curriculum",
        },
        EVENTS: {
          ROOT: `${PRODUCT_DETAIL_ROOT}/reports/progress/events`,
          DRAWER_PATH: "/drawer/:productMembershipId/events",
        },
        COMMENTS: {
          ROOT: `${PRODUCT_DETAIL_ROOT}/reports/progress/comments`,
          DRAWER_PATH: "/drawer/:productMembershipId/comments",
        },
      },
    },
    CERTIFICATES: {
      ROOT: `${PRODUCT_DETAIL_ROOT}/certificates`,
      LIST: `${PRODUCT_DETAIL_ROOT}/certificates/list`,
      ADD: `${PRODUCT_DETAIL_ROOT}/certificates/add`,
      EDIT: `${PRODUCT_DETAIL_ROOT}/certificates/:certificateTemplateId/edit`,
    },
    NOT_FOUND: `${PRODUCT_DETAIL_ROOT}/*`,
    WEBVIEW: {
      ROOT: `${PRODUCT_DETAIL_ROOT}/webview`,
      BOOKMARKS: `${PRODUCT_DETAIL_ROOT}/webview/bookmarks`,
      PROFILE: `${PRODUCT_DETAIL_ROOT}/webview/profileSettings`,
      QUIZ: `${PRODUCT_DETAIL_ROOT}/webview/quiz`,
      CONTENT: {
        SYSTEM_TASK: {
          ADD_EVENTS_TO_CALENDER: `${PRODUCT_DETAIL_ROOT}/webview/content/systemTask/addEventsToCalendar`,
          COMPLETE_PROFILE: `${PRODUCT_DETAIL_ROOT}/webview/content/systemTask/completeProfile`,
        },
      },
    },
  },

  CHAT: {
    ROOT: CHAT_ROUTE_PREFIX,
    CHANNEL: {
      ROOT: `${CHAT_ROUTE_PREFIX}/channel`,
      DETAIL: `${CHAT_ROUTE_PREFIX}/channel/:channelId`,
      DETAIL_EMPTY: `${CHAT_ROUTE_PREFIX}/channel/empty`,
    },
  },

  STRIPE_INTEGRATION: `${ROOT_ROUTE}stripe-integration`,
  ZOOM_INTEGRATION: `${ROOT_ROUTE}zoom-integration`,
  ZOOM_LANDING: `${ROOT_ROUTE}zoom-landing`,
  AUTO_RSVP: `${ROOT_ROUTE}auto-rsvp`,

  OUTLOOK_INTEGRATION: `${ROOT_ROUTE}outlook-integration`,
  OUTLOOK_LANDING: `${ROOT_ROUTE}outlook-landing`,

  PAYMENT: { UPDATE: `${ROOT_ROUTE}payment/update` },

  MARKETING_OPT_OUT: `${ROOT_ROUTE}marketing/opt-out`,
  NOTIFICATION_OPT_OUT: `${ROOT_ROUTE}notification-opt-out`,
  CONTENT_OPT_OUT: `${ROOT_ROUTE}content-opt-out`,
  REACTIVATE_TRIAL: `${ROOT_ROUTE}reactivate-trial`,

  CREATE_TEST_USER: `${ROOT_ROUTE}create-ul`,
  DELETE_TEST_USER: `${ROOT_ROUTE}delete-ul`,

  E2E_PRELOAD: `${ROOT_ROUTE}e2e-preload`,
} as const

export default ROUTE_NAMES

/** Get all recursive string route values like `routeValues(ROUTE_NAMES.ONBOARDING)` */
export function routeValues(
  routes: Record<string, string | Record<string, string>>
): string[] {
  return Object.values(routes).reduce((acc, val) => {
    if (typeof val === "string") {
      acc.push(val)
    } else {
      acc.push(...routeValues(val))
    }
    return acc
  }, [] as string[])
}

export {
  ADMIN_ROUTE_PREFIX,
  BRAIN_ROUTE_PREFIX,
  CHANNEL_ROUTE_PREFIX,
  CHAT_ROUTE_PREFIX,
  COMMUNITY_EVENTS_PREFIX,
  COMMUNITY_ROUTE_PREFIX,
  CURRICULUM_ROUTE_PREFIX,
  DM_DETAIL_ROUTE_PREFIX,
  ONBOARDING_ROUTE_PREFIX,
  PRODUCT_DETAIL_ROOT,
  PRODUCT_ROUTE_PREFIX,
  ROOT_ROUTE,
  THREAD_DETAIL_ROUTE_PREFIX,
}
